<template>
    <v-row justify="center">
     
        <v-col>
            <v-data-table :loading="loading" :items="items" :search="search" :headers="headers">
                <template v-slot:top>
                    <v-row>
                        <v-col cols="10">
                            <v-text-field 
                                v-model="search" 
                                label="Pesquisar" 
                                class="mx-0">
                            </v-text-field>
                        </v-col>
                        <v-col class="text-right">
                            <Editar :currentItem="{}" />
                        </v-col>
                        <v-col cols="1" class="text-right">
                            <v-btn icon @click="get_operadoras()"><v-icon>mdi-refresh</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.editar`]="{ item }">
                    <Editar :currentItem="item"/>
                </template>
                
            </v-data-table>
        </v-col>
    </v-row>
</template> 
<script>
import api from '../../http';

export default {
    name: 'Operdaoras',
    components: {
        Editar: () => import("./componentes/operadoras/Editar.vue"),
    },
    data: () => ({
        loading: false,
        items: [],
        search: '',
        
        headers: [
            { text: "ID", sortable:true, value: "id" },
            { text: "Nome Fantasia", sortable: true, value: "nome" },
            { text: "Razão Social", sortable: true, value: "razao" },
            { text: "Empresa ou Operadora", sortable: true, value: "empresa" },
            { text: "ANS", sortable:true, value: "ans" },
            { text: "CNES", sortable: true, value: "cnes" },
            { text: "Código na Operadora", sortable: true, value: "cod_hc_slz" },
            { text: "Editar",  sortable:true, align:"end", value: "editar" },
        ],
    }),
    methods: {
        get_operadoras() {
            api('operadoras/operadoras/').then((response) => {
                this.items = response.data
            })
        },
  
      
    },
    mounted() {
        this.get_operadoras()
    }
}
</script>  